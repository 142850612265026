<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Gastos</h1>
    </div>
    <br />

    <DataGrid :config="dataGridConfig" :data="gastos" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-2"><button class="btn secondary-btn" @click="revisar_gasto">Revisar gasto</button></div>
        <div class="col-sm-2"><button class="btn complementary-btn" @click="gasto_nuevo=true">Nuevo gasto</button></div>
        <div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-sm-2"><button class="btn principal-btn" @click="ver_prepago=true">Pagar gastos{{ total_gastos_por_pagar > 0 ? ' ('+total_gastos_por_pagar+')' : '' }}</button></div>
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_gastos">Recargar</button></div>
      </div>
    </DataGrid>

    <GastoNuevo v-if="gasto_nuevo" :autorizadores="autorizadores" @close="gasto_nuevo_toggle" />

    <Detalle v-if="ver_detalles" :gasto_id="gasto" :autorizadores="autorizadores" @close="cerrar_detalles" />

    <Pago v-if="ver_prepago" @close="ver_prepago=false" @revisar="revisar_gasto_pago"/>
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import GastoNuevo from './Gasto/Nuevo'
  import Detalle from './Gasto/Detalle'
  import Pago from './Gasto/Pago'

  import api from '@/apps/tesoreria/api/gastos'

  export default {
    components: {
      DataGrid, GastoNuevo, Detalle, Pago
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'gastos'
          ,cols: {
            usuario: 'Usuario'
            ,gasto: 'Gasto'
            ,monto: 'Monto'
            ,estatus: 'Estatus'
            ,created_at: 'Creación'
          }
          ,filters: {
            cols: {
              id: 'ID'
              ,solicitud_id: 'Solicitud'
              ,nombre: 'Nombre'
              ,created_at: 'Fecha de creación'
            }
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            monto: function(val, row, vue) {
              val = val.toString().replace(/[^\d\.]/g, "");
              return '$'+vue.$helper.moneyFormat(val);
            },
            estatus: function(val) {
              switch(val) {
                case 0:
                  return 'Rechazada';
                  break;
                case 1:
                  return 'Sin revisión';
                  break;
                case 2:
                  return 'Aceptada sin revisión';
                  break;
                case 3:
                  return 'Revisión finanzas';
                  break;
                case 4:
                  return 'En proceso de pago';
                  break;
                case 5:
                  return 'Pagada';
                  break;
              }
            }
            ,created_at: function(val, row, vue) {
              return vue.$moment(val).format('YYYY-DD-MM hh:mm:ss')
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,catalogo: {
          nombre:null
          ,valores: []
        }
        ,gastos: []
        ,gasto_nuevo: false
        ,ver_detalles: false
        ,gasto: null
        ,ver_prepago: false
        ,total_gastos_por_pagar: 0
        ,autorizadores: []
      }
    }
    ,mounted: function() {
      this.obtener_autorizadores();
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        this.$log.info('tipo', tipo);
        if (tipo == 'options') {
          this.options = data;
          this.obtener_gastos();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_gastos: async function() {
        try {
          let res;

          if (this.$auth.can('tesoreria','gastos_operador'))
            res = (await api.obtener_gastos(this.options)).data;
          else if (this.$auth.can('tesoreria','tesoreria_operador'))
            this.total_gastos_por_pagar = (await api.contar_gastos_pagar()).data;
          else if (this.$auth.can('tesoreria','tesoreria_autorizador_finanzas'))
            res = (await api.obtener_gastos_directivos(this.options)).data;
          else if (this.$auth.can('tesoreria','tesoreria_autorizador'))
            res = (await api.obtener_gastos_autorizador(this.options)).data;
          else if (this.$auth.can('tesoreria','gastos_misgastos_listar'))
            res = (await api.obtener_mis_gastos(this.options)).data;

          this.gastos = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_autorizadores: async function() {
        try {
          if (!this.$auth.can('tesoreria','listar_autorizadores'))
            return;
          
          this.autorizadores = (await api.obtener_autorizador()).data;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,gasto_nuevo_toggle: function(show) {
        this.gasto_nuevo = show;
        this.obtener_gastos();
      }
      ,revisar_gasto: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar un gasto a revisar','error','alert');

        this.ver_detalles = true;
        this.gasto = this.seleccionadas[0].id;
      }
      ,cerrar_detalles: function() {
        this.ver_detalles=false;
        this.seleccionar = false;
        this.obtener_gastos();
      }
      ,revisar_gasto_pago: function(gasto) {
        this.ver_detalles = true;
        this.gasto = gasto.id;
      }
    }
  }
</script>

<style lang="scss">
  
</style>