<template>
  <Modal @close="cerrar_gasto">
    <div class="title">Detalles de gasto</div>
    <div class="body">
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Gasto</label>
        <div class="col-sm-10"><span class="form-control">{{ gasto.gasto }}</span></div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Área</label>
        <div class="col-sm-10"><span class="form-control">{{ obtener_area(gasto.autorizador_id) }}</span></div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Monto</label>
        <div class="col-sm-10"><span class="form-control">${{ $helper.moneyFormat(gasto.monto) }}</span></div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Fecha envio</label>
        <div class="col-sm-10"><span class="form-control">{{ $moment(gasto.created_at).format('YYYY-MM-DD hh:mm:ss') }}</span></div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Documentos</label>
        <div class="col-sm-9 file_list_container texto-menor">
          <div class="row header">
            <div class="col-sm-9 my-auto">Archivo</div>
            <div class="col-sm-3 text-center">Opciones</div>
          </div>
          <template v-if="gasto.documentos.length > 0">
          <div v-for="(archivo,index) in gasto.documentos" :key="index" class="row file_list">
            <div class="col-sm-9 my-auto">{{ archivo.nombre }}</div>
            <div class="col-sm-3 text-center"><button class="btn btn-secondary" @click="descargar_documento(archivo)">Descargar</button></div>
          </div>
          </template>
          <div v-else class="row file_list">
            <div class="col-sm-12">No hay documentos</div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-2">Observaciones</label>
        <div class="col-sm-9 texto-menor">
          <div class="row header">
            <div class="col-sm-10">Observación</div>
            <div class="col-sm-2">Fecha</div>
          </div>
          <template v-if="gasto.observaciones.length > 0">
          <div v-for="observacion in gasto.observaciones" :key="observacion.id" class="row row_observation">
            <div class="col-sm-9">{{ observacion.nombre }}: {{ observacion.observaciones }}</div>
            <div class="col-sm-3 text-center">{{ $moment(observacion.created_at).format('YYYY-MM-DD hh:mm:ss') }}</div>
          </div>
          </template>
          <div v-else class="row">
            <div class="col-sm-12">No hay observaciones</div>
          </div>
        </div>
      </div>
      <form @submit.prevent="agregar_observacion">
      <div class="row form-group">
        <div class="col-sm-7 offset-sm-2"><input v-model="observacion" type="text" name="nueva_observacion" id="nueva_observacion" class="form-control" placeholder="Nueva observación" autocomplete="off"></div>
        <div class="col-sm-2"><button class="btn secondary-btn">Agregar</button></div>
      </div>
      </form>
    </div>
    <div class="footer">
      <div class="row">
        <div v-if="reviar_permisos" class="col-sm-2 offset-sm-6"><button class="btn complementary-btn" @click="actualizar_estatus(0)">Rechazar</button></div>
        <div v-if="reviar_permisos" class="col-sm-2"><button class="btn principal-btn" @click="actualizar_estatus(1)">Aprobar</button></div>
        <div :class="clases_boton()"><button class="btn secondary-btn" @click="cerrar_gasto">Cerrar</button></div>
      </div>
    </div>
  </Modal>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/tesoreria/api/gastos'

  export default {
    components: {
      Modal
    }
    ,props: {
      gasto_id: null
      ,autorizadores: {
        type: Array
        ,default: function() {
          return [];
        }
      }
    }
    ,data: function() {
      return {
        observacion: null
        ,gasto: {
          gasto: null
          ,area: null
          ,usuario: null
          ,estatus: null
          ,created_at: null
          ,documentos: []
          ,observaciones: []
        }
      }
    }
    ,mounted: async function() {
      try {
        if (this.$auth.can('tesoreria','gastos_operador') || this.$auth.can('tesoreria','tesoreria_autorizador_finanzas') || this.$auth.can('tesoreria','tesoreria_autorizador'))
          this.gasto = (await api.obtener_gasto(this.gasto_id)).data;
        else if (this.$auth.can('tesoreria','gastos_misgastos_listar'))
          this.gasto = (await api.obtener_mis_gastos_id(this.gasto_id)).data;
      }catch(e) {
        this.$log.info('Error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,methods: {
      obtener_area: function(area) {
        let nombre = '';

        this.autorizadores.forEach(autorizador => {
          if (autorizador.id == area)
            nombre = autorizador.area;
        })

        return nombre;
      }
      ,cerrar_gasto: function() {
        this.$emit('close');
      }
      ,descargar_documento: async function(archivo) {
        try {
          let file = (await api.descargar_documentos(archivo.id));
          this.$log.info('file', file.headers);
          
          let urlFile = window.URL.createObjectURL(new Blob([file.data], {type: archivo.mime}));
          const link = document.createElement('a');
          link.href = urlFile;
          link.setAttribute('download',  archivo.nombre);

          document.body.appendChild(link);

          link.click();
          link.remove();
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,clases_boton: function() {
        if (['2','3','4'].indexOf(this.gasto.estatus) != -1)
          return 'col-sm-2 offset-sm-10';
        else
          return 'col-sm-2';
      }
      ,actualizar_estatus: async function(tipo) {
        try {
          let estatus = 0;

          if (tipo == 1) {
            if (this.gasto.estatus == 1 && this.$auth.can('tesoreria','tesoreria_operador'))
              estatus = 2
            else if (this.gasto.estatus == 2 && (this.$auth.can('tesoreria','tesoreria_autorizador') || this.$auth.can('tesoreria','tesoreria_autorizador_finanzas')))
              estatus = 4
          }

          let res = (await api.actualiza_estatus(this.gasto.id, estatus)).data;
          this.$log.info('res', res);

          this.$emit('close');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,agregar_observacion: async function() {
        try {
          if (this.observacion == '')
            return this.$helper.showMessage('Error','Tienes que agregar un cuerpo en la observación','error','alert');

          this.gasto.observaciones = (await api.agregar_observacion(this.gasto.id, {observacion: this.observacion})).data;
          this.observacion = null;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,computed: {
      reviar_permisos: function() {
        this.$log.info('estatus: '+this.gasto.estatus);
        this.$log.info('tesoreria_operador: ',this.$auth.can('tesoreria','tesoreria_operador'));
        this.$log.info('tesoreria_autorizaciones: ',this.$auth.can('tesoreria','tesoreria_autorizaciones'));
        this.$log.info('tesoreria_autorizador: ',this.$auth.can('tesoreria','tesoreria_autorizador'));
        if (this.$auth.can('tesoreria','*') && this.gasto.estatus == 1) {
          return true;
        }else if (this.$auth.can('tesoreria','tesoreria_operador') && this.gasto.estatus == 1) {
          return true;
        }else if (this.$auth.can('tesoreria','tesoreria_autorizador') && this.gasto.estatus == 2) {
          return true;
        }else if (this.$auth.can('tesoreria','tesoreria_autorizador_finanzas') && this.gasto.estatus == 3) {
          return true;
        }

        return false;
      }
    }
  }
</script>

<style lang="scss">
  .file_list_container {
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }

  .texto-menor {
    font-size: 0.8em !important;

    button {
      font-size: 0.8em !important;
    }
  }

  .row_observation {
    div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .row_observation:nth-child(2n+1) {
    background-color: #E6E6E6;
  }
</style>